// @ts-nocheck
import "budicons2/Outline/style.css";
import "./style/style.scss";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "element-closest/browser";
import cssVars from "css-vars-ponyfill";
import tableCheck from "./scripts/tableCheckAll";
import contextMenu from "./scripts/contextMenu";
import modal from "./scripts/modal";
import tabs from "./scripts/tabs";
import tableToggle from "./scripts/tableToggle";
import tableIconToggle from "./scripts/tableIconToggle";
import editToggle from "./scripts/editToggle";
import tableCheckCounter, { syncRowHeight } from "./scripts/tableCheckCounter";
import tableFilter from "./scripts/tableFilter";
import SkjemaAdminSideMenu from "./scripts/sideMenu";
import fileUploadWidget from "./scripts/fileUpload";
import tinymce from "tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/image";
import "tinymce/plugins/imagetools";
// @ts-ignore
import Stickyfill from "stickyfilljs/dist/stickyfill";
import {categoriesApiCall, copyDesignerForm, moveDesignerForm, createDesignerForm, deleteDesignerFormVersion, filterTableFragment, getFormVersions, isFormInEditMode, renderVersionsTableBody, sortTableRow, shareForm, checkIfFormIsConvertible, convertDesignerForm, unlockDesignerForm, adminAccess} from "./scripts/designer";
import {startup} from "./scripts/startup/startup";

export const SideMenu = SkjemaAdminSideMenu;
export const setTableCheckAll = (tableSelector: string) => {tableCheck(tableSelector, "")};
export const setTableToggle = tableToggle;
export const setTableParameterIcon = tableIconToggle;
export const setContextMenu = contextMenu;
export const setModal = modal;
export const setTabs = tabs;
export const setEditToggle = editToggle;
export const setTableCheckCounter = tableCheckCounter;
export const syncTableTdHeight = syncRowHeight;
export const setFileUploadWidget = fileUploadWidget;
export const setTableFilter = tableFilter;
export const setUnlockDesignerForm = unlockDesignerForm;
export const setAdminAccess = adminAccess;
export const tinyMCEObj = tinymce;
export const getFilterTableFragment = filterTableFragment;
export const getCreateDesignerForm = createDesignerForm;
export const getDeleteDesignerFormVersion = deleteDesignerFormVersion;
export const getCopyDesignerForm = copyDesignerForm;
export const getMoveDesignerForm = moveDesignerForm;
export const getVersions = getFormVersions;
export const getVersionsTbody = renderVersionsTableBody;
export const getCategories = categoriesApiCall;
export const getIsFormInEditMode = isFormInEditMode;
export const getCheckIfFormIsConvertible = checkIfFormIsConvertible;
export const getConvertDesignerForm = convertDesignerForm;
export const setShareForm = shareForm;
export const initPolyfill = () => {
	startup();
    const elements = document.querySelector(".skjema-admin__start-bar");

    if (!elements) {
        throw Error("skjema-admin__start-bar not found, polyfill not applied.");
    }

    Stickyfill.add(elements);

    cssVars({
        //Fill out variables if we need to edit them
    });
};
